import moment from "moment";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import "./App.css";
import { getPadStart, SERVER } from "./utils";
import { PDFExport } from "@progress/kendo-react-pdf";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontWeight: "bold",
    fontSize: 18,
  },
  titleCtn: {
    border: "2px solid #000",
    padding: 10,
  },
  table: {
    width: "100%",
    border: "1px solid #000",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    // border: '1px solid #EEE',
    // paddingTop: 8,
    // paddingBottom: 8,
  },
  header: {
    border: "1px solid #000",
    backgroundColor: "#642f93",
  },
  bold: {
    fontWeight: "bold",
  },
  row1: {
    width: "20%",
    border: "1px solid #000",
    padding: "4px",
    fontSize: 12,
  },
  row2: {
    width: "10%",
    border: "1px solid #000",
    padding: "4px",
    fontSize: 12,
    textAlign: "center",
  },
  tableTitle: {
    fontWeight: "bold",
    textDecoration: "underline",
  },
  tableRow: {
    border: "1px solid #000",
    fontSize: 11,
  },
});

function AdminDetailForm() {
  const navigate = useNavigate();
  const params = useParams();

  const zipRef = localStorage.getItem("zipRef");

  const pdfExportComponent = React.useRef(null);

  const [generalInfos, setGeneralInfos] = useState({});
  const [item, setItem] = useState({});
  const [pdfVisible, setPdfVisible] = useState(false);
  const [parcoursRonde, setParcoursRonde] = useState([]);
  const [selectedParcours, setSelectedParcours] = useState({});

  const onGenerate = (toPdf) => {
    pdfExportComponent.current.save();
    setPdfVisible(true);
    // if (pdfExportComponent.current) {
    // }
    // toPdf();
  };

  const addGenInfos = (position) => {
    setGenInfos({
      ...genInfos,
      [position]: {
        title: "",
        position,
      },
    });
  };

  const removeGenInfos = (position) => {
    if (Object.values(genInfos).length > 1) {
      setGenInfos((current) => {
        const copy = { ...current };
        delete copy[position];
        return copy;
      });
    }
  };

  const onChangeRonde = (id, value) => {
    setSelectedParcours({ ...selectedParcours, [id]: { id, value } });
  };

  const getSsiColor = (value) => {
    if (value === "OK") {
      return "#3ba44d";
    } else if (value === "Dérangement") {
      return "#ffc038";
    } else {
      return "#dd3d45";
    }
  };

  const PDFFooter = (props) => (
    <div className="px-5">
      <p style={{ fontSize: 11, textAlign: "center" }}>
        Auorisation d'exercer CNAPS : AUT-078-2120-08-06-20210785844 Article
        L612-14 du LIVRE VI DU CSI L'autorisation d'exercice ne confère aucune
        prérogative de puissance publique à l'entreprise ou aux personnes qui en
        bénéficient
      </p>
    </div>
  );

  const ParcoursTable = ({ data }) => {
    return (
      <table>
        <tr style={{ ...styles.header }}>
          <th
            style={{
              ...styles.tableRow,
              width: 80,
              color: "#fff",
              textAlign: "center",
            }}
          >
            Position
          </th>
          <th
            style={{ ...styles.tableRow, color: "#fff", textAlign: "center" }}
          >
            Localisation pointeaux
          </th>
          <th
            style={{ ...styles.tableRow, color: "#fff", textAlign: "center" }}
          >
            Points de contrôle
          </th>
          <th
            style={{
              ...styles.tableRow,
              color: "#fff",
              width: 100,
              textAlign: "center",
            }}
          >
            Etat
          </th>
        </tr>
        {data.map((row, i) => (
          <tr key={i} style={styles.tableRow}>
            <td style={{ ...styles.tableRow, textAlign: "center", width: 80 }}>
              {i + 1}
            </td>
            <td style={styles.tableRow}>{row.localisation}</td>
            <td style={styles.tableRow}>{row.pointsControl}</td>
            <td
              style={{
                ...styles.tableRow,
                width: 100,
                color: !selectedParcours[row.id]?.value ? "#3ba44d" : "#ffc038",
              }}
            >
              {!selectedParcours[row.id]?.value
                ? "RAS"
                : "Anomalie (voir synthèse)"}
            </td>
          </tr>
        ))}
      </table>
    );
  };

  const ObservationTable = ({ data }) => {
    return (
      <table>
        <tr style={{ ...styles.header }}>
          <th
            style={{
              fontSize: 11,
              backgroundColor: "#fff",
              borderWidth: 0,
              borderBottom: "1px solid #000",
            }}
          ></th>
          <th style={{ ...styles.row2, color: "#fff" }}>Heures</th>
          <th style={{ ...styles.row2, color: "#fff" }}>Clim14</th>
          <th style={{ ...styles.row2, color: "#fff" }}>Clim15</th>
          <th style={{ ...styles.row2, color: "#fff" }}>Clim16</th>
          <th style={{ ...styles.row2, color: "#fff" }}>Clim25</th>
        </tr>
        {data.map((row, i) => (
          <tr key={i} style={styles.tableRow}>
            <td style={{ ...styles.tableRow }}>{row.nom}</td>
            <td style={{ ...styles.row2 }}>
              {row.heure !== "" ? String(row.heure)?.replace(":", "h") : "0h0"}
            </td>
            <td
              style={{
                ...styles.row2,
                backgroundColor: !row.clim14 ? "#c2c2c250" : "#fff",
              }}
            >
              {row.clim14}
            </td>
            <td
              style={{
                ...styles.row2,
                backgroundColor: !row.clim15 ? "#c2c2c250" : "#fff",
              }}
            >
              {row.clim15}
            </td>
            <td
              style={{
                ...styles.row2,
                backgroundColor: !row.clim16 ? "#c2c2c250" : "#fff",
              }}
            >
              {row.clim16}
            </td>
            <td
              style={{
                ...styles.row2,
                backgroundColor: !row.clim25 ? "#c2c2c250" : "#fff",
              }}
            >
              {row.clim25}
            </td>
          </tr>
        ))}
      </table>
    );
  };

  const MyDocument = () => (
    // <div ref={ref}>
    <PDFExport
      paperSize="A4"
      scale={0.7}
      fileName={`${generalInfos.siteName}-${new Date(
        item.createdAt
      ).toLocaleDateString()}-rapport.pdf`}
      forcePageBreak=".page-break"
      margin="1cm"
      ref={pdfExportComponent}
      pageTemplate={PDFFooter}
    >
      <div style={{ height: "297mm" }}>
        <div>
          <div className="d-flex justify-content-between w-100">
            <img src="/company.png" height={"85px"} alt="logo" />
            <img src="/logo_full.png" height={"60px"} alt="logo" />
          </div>
          <p className="text-center">
            <View style={styles.titleCtn}>
              <Text style={styles.title}>COMPTE RENDU DE VACATION</Text>
            </View>
          </p>
          <br />
          <div className="d-flex">
            <div className="d-flex mx-2">
              <p style={{ fontWeight: "bolder", marginRight: 5, fontSize: 14 }}>
                Vacation du :
              </p>
              <p style={{ fontSize: 14 }}>
                {generalInfos?.vacationDu} au {generalInfos?.vacationAu}
              </p>
            </div>
            <div className="d-flex mx-2">
              <p style={{ fontWeight: "bolder", marginRight: 5, fontSize: 14 }}>
                de :
              </p>
              <p style={{ fontSize: 14 }}>
                {generalInfos?.horaire?.replace("-", "à")}
              </p>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex mx-2">
              <p style={{ fontWeight: "bolder", marginRight: 5, fontSize: 14 }}>
                Nom du site :
              </p>
              <p style={{ fontSize: 14 }}>{generalInfos.siteName}</p>
            </div>
            <div className="d-flex mx-2">
              <p style={{ fontWeight: "bolder", marginRight: 5, fontSize: 14 }}>
                Adresse :
              </p>
              <p style={{ fontSize: 14 }}>{generalInfos.adresse}</p>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex mx-2">
              <p style={{ fontWeight: "bolder", marginRight: 5, fontSize: 14 }}>
                N° du rapport :
              </p>
              <p style={{ fontSize: 14 }}>{getPadStart(item.reportNum)}</p>
            </div>
            <div className="d-flex mx-2">
              <p style={{ fontWeight: "bolder", marginRight: 5, fontSize: 14 }}>
                du :
              </p>
              <p style={{ fontSize: 14 }}>
                {new Date(item.createdAt).toLocaleDateString()}
              </p>
            </div>
          </div>
          <div style={{ padding: 1, backgroundColor: "#000" }} />
          <br />

          <ParcoursTable data={parcoursRonde} />
          <br />

          <Text style={styles.tableTitle}>
            Observations particulières Datacenter
          </Text>
          <br />
          <br />

          <ObservationTable data={Object.values(obParticuliere)} />
        </div>

        <div className="page-break">
          <br />
          <Text style={styles.tableTitle}>Etat des SSI</Text>
          <br />
          <div className="d-flex">
            <p style={{ fontWeight: "bolder", marginRight: 5, fontSize: 14 }}>
              IT Room :
            </p>
            <p
              style={{
                fontSize: 14,
                color: getSsiColor(generalInfos?.itRoom),
                fontWeight: "bold",
              }}
            >
              {generalInfos?.itRoom}
            </p>
          </div>
          <div className="d-flex">
            <p style={{ fontWeight: "bolder", marginRight: 5, fontSize: 14 }}>
              Data Center :
            </p>
            <p
              style={{
                fontSize: 14,
                color: getSsiColor(generalInfos?.dataCenter),
                fontWeight: "bold",
              }}
            >
              {generalInfos?.dataCenter}
            </p>
          </div>

          <Text style={styles.tableTitle}>Observations Générales</Text>
          <br />
          <br />
          <div style={{ border: "1px solid #000" }}>
            <p
              className="mx-2"
              style={{ color: "#3ba44d", fontWeight: "bold" }}
            >
              *** Informations d'ordre générales & Synthèse ***
            </p>
            {Object.values(genInfos).map((val, inx) => (
              <p key={inx} className="mx-4">
                - {val.title}
              </p>
            ))}
            <div style={{ padding: 1, backgroundColor: "#000" }} />
            <br />
            <p
              className="mx-2"
              style={{ color: "#dd3d45", fontWeight: "bold" }}
            >
              *** Incident de sécurité ***
            </p>
            <p className="mx-4">{generalInfos.incident}</p>
            {/* {Object.values(incident).map((val, inx) => (
              <p key={inx} className="mx-4">
                - {val.title}
              </p>
            ))} */}
          </div>
        </div>
      </div>
      {/* <footer><p>test footer</p></footer> */}
    </PDFExport>
    // </div>
  );

  const getRequests = async () => {
    const result = await axios.get(`${SERVER}reports/${params.id}`);

    if (result.data) {
      setGeneralInfos(JSON.parse(result.data.generalInfos));
      setGenInfos(JSON.parse(result.data.genInfos));
      setIncidents(JSON.parse(result.data.incident));
      setObParticuliere(JSON.parse(result.data.obParticuliere));
      setItem(result.data);
      setSelectedParcours(JSON.parse(result.data?.selectedParcours || "{}"));
    }
    // setReports(result.data);
  };

  const [obParticuliere, setObParticuliere] = useState({
    1: {
      nom: "Premier relevé T°",
      heure: new Date(),
      clim14: "",
      clim15: "",
      clim16: "",
      clim25: "",
      position: 1,
    },
    2: {
      nom: "Dernier relevé T°",
      heure: new Date(),
      clim24: "",
      clim15: "",
      clim16: "",
      clim25: "",
      position: 2,
    },
    3: {
      nom: "Premier relevé Hygrométrie",
      heure: new Date(),
      clim24: "",
      clim15: "",
      clim16: "",
      clim25: "",
      position: 3,
    },
    4: {
      nom: "Dernier relevé Hygrométrie",
      heure: new Date(),
      clim24: "",
      clim15: "",
      clim16: "",
      clim25: "",
      position: 4,
    },
  });

  const [genInfos, setGenInfos] = useState({
    1: {
      title: "",
      position: 1,
    },
  });

  const [incident, setIncidents] = useState({
    1: {
      title: "",
      position: 1,
    },
  });

  const onObservationsChange = (position, value, key) => {
    if (key === "heure") {
      if ([1, 3].includes(position)) {
        setObParticuliere({
          ...obParticuliere,
          1: {
            ...obParticuliere[1],
            [key]: value,
          },
          3: {
            ...obParticuliere[3],
            [key]: value,
          },
        });
      } else if ([2, 4].includes(position)) {
        setObParticuliere({
          ...obParticuliere,
          2: {
            ...obParticuliere[2],
            [key]: value,
          },
          4: {
            ...obParticuliere[4],
            [key]: value,
          },
        });
      }
      return;
    }

    setObParticuliere({
      ...obParticuliere,
      [position]: {
        ...obParticuliere[position],
        [key]: value,
      },
    });
  };

  const resetFields = () => {
    setGeneralInfos({
      siteName: "SIPARTECH",
      adresse: "150 rue Gallieni, 92100 Boulogne-Billancourt",
      vacationDu: moment(new Date()).format("YYYY-MM-DD"),
      vacationAu: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
      horaire: "07h00 - 19h00",
      agent: "",
      itRoom: "Dérangement",
      dataCenter: "HS",
      incident: "",
    });

    setObParticuliere({
      1: {
        nom: "Premier relevé T°",
        heure: new Date(),
        clim14: "",
        clim15: "",
        clim16: "",
        clim25: "",
        position: 1,
      },
      2: {
        nom: "Dernier relevé T°",
        heure: new Date(),
        clim24: "",
        clim15: "",
        clim16: "",
        clim25: "",
        position: 2,
      },
      3: {
        nom: "Premier relevé Hygrométrie",
        heure: new Date(),
        clim24: "",
        clim15: "",
        clim16: "",
        clim25: "",
        position: 3,
      },
      4: {
        nom: "Dernier relevé Hygrométrie",
        heure: new Date(),
        clim24: "",
        clim15: "",
        clim16: "",
        clim25: "",
        position: 4,
      },
    });

    setGenInfos({
      1: {
        title: "",
        position: 1,
      },
    });

    setIncidents({
      1: {
        title: "",
        position: 1,
      },
    });
  };

  const onSave = async (deleted = false) => {
    try {
      const toSave = {
        generalInfos,
        obParticuliere,
        genInfos,
        incident,
        selectedParcours,
        isDeleted: deleted,
      };

      const result = await axios.put(SERVER + "reports", {
        ...item,
        ...toSave,
      });
      // localStorage.setItem('storedVal',toSave)
      if ([201, 200].includes(result.status)) {
        alert(
          deleted
            ? "Suppression reussi"
            : params?.id
            ? "Modification reussi"
            : "Enregistrement reussi"
        );
        resetFields();
        navigate("/admin-home");
      } else {
        alert("Une erreur est survenue");
      }
    } catch (error) {
      console.log(error);
      alert("Une erreur est survenue");
    }
  };

  const sendPdf = async () => {
    const result = await axios.post(SERVER + "reports-pdf", item);
  };

  const getParcours = async () => {
    const result = await axios.get(SERVER + "parcours");
    setParcoursRonde(result.data);
  };

  const onDelete = async () => {
    if (
      window.confirm(
        "Souhaitez-vous supprimer ce rapport ? Cette opération est irréversible !"
      )
    ) {
      onSave(true);
    }
  };

  const goBack = () => {
    navigate("/admin-home");
  };

  useEffect(() => {
    getParcours();
    getRequests();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // return (
  //   <div style={{ padding: 16 }}>
  //     <MyDocument />
  //   </div>
  // );

  return (
    <Container className="mt-5">
      <>
        <div className="d-flex justify-content-center w-100">
          <img src="/logo_full.png" height={"150px"} alt="logo" />
        </div>
        <h1 className="text-center mb-5 fw-bold">Compte rendu de vacation</h1>
        {/* Informations Générales */}
        <Card>
          <Card.Header className="bg-black text-white">
            Informations Générales
          </Card.Header>
          <Card.Body>
            <Row className="mb-3">
              <Col xs={12} md={6} className="py-2">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Nom du site</Form.Label>
                  <Form.Control
                    type="text"
                    value={generalInfos.siteName}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        siteName: target.value,
                      })
                    }
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="py-2">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Adresse</Form.Label>
                  <Form.Control
                    type="text"
                    value={generalInfos.adresse}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        adresse: target.value,
                      })
                    }
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={6} className="py-2">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Vacation du</Form.Label>
                  <Form.Control
                    type="date"
                    value={generalInfos.vacationDu}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        vacationDu: target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="py-2">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Au</Form.Label>
                  <Form.Control
                    type="date"
                    value={generalInfos.vacationAu}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        vacationAu: target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={6} className="py-2">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Horaire</Form.Label>
                  {/* <Form.Control
                  type="time"
                  value={generalInfos.vacationDe}
                  onChange={({ target }) =>
                    setGeneralInfos({
                      ...generalInfos,
                      vacationDe: target.value,
                    })
                  }
                /> */}
                  <Form.Select
                    aria-label="Horaire"
                    value={generalInfos.horaire}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        horaire: target.value,
                      })
                    }
                  >
                    <option value="07h00 - 19h00">07h00 - 19h00</option>
                    <option value="18h - 07h">18h - 07h</option>
                    <option value="19h - 07h">19h - 07h</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="py-2">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Agent</Form.Label>
                  <Form.Control
                    type="text"
                    value={generalInfos.agent}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        agent: target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={6} className="py-2">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Date et heure de saisi</Form.Label>
                  <Form.Control
                    type="text"
                    value={new Date(item.createdAt).toLocaleString('fr-FR')}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="py-2">
                {/* <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Adresse</Form.Label>
                  <Form.Control
                    type="text"
                    value={generalInfos.adresse}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        adresse: target.value,
                      })
                    }
                    disabled
                  />
                </Form.Group> */}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* Parcours de ronde */}
        <Card className="mt-5">
          <Card.Header className="bg-black text-white mb-2">
            Parcours de ronde
          </Card.Header>

          <Table responsive="sm" striped={true}>
            <thead>
              <tr>
                <th>Position</th>
                <th>Localisation pointeaux</th>
                <th>Points de contrôle</th>
                <th>Etat</th>
              </tr>
            </thead>
            <tbody>
              {parcoursRonde.map((val, inx) => (
                <tr key={inx}>
                  <td>{inx + 1}</td>
                  <td>{val.localisation}</td>
                  <td>{val.pointsControl}</td>
                  <td>
                    <div className="d-flex">
                      <Form.Check
                        inline
                        label="RAS"
                        name="group1"
                        type={"checkbox"}
                        id={`inline-checkbox-1`}
                        style={{ color: "#3ba44d" }}
                        onChange={() => onChangeRonde(val.id, false)}
                        checked={!selectedParcours[val.id]?.value}
                      />
                      <Form.Check
                        inline
                        label="Anomalie"
                        name="group1"
                        type={"checkbox"}
                        id={`inline-checkbox-2`}
                        style={{ color: "#ffc038" }}
                        onChange={() => onChangeRonde(val.id, true)}
                        checked={selectedParcours[val.id]?.value}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>

        {/* Observations particulières Datacenter */}
        <Card className="mt-5">
          <Card.Header className="bg-black text-white">
            Observations particulières Datacenter
          </Card.Header>
          <Card.Body>
            {Object.values(obParticuliere).map((val, inx, arr) => (
              <div key={inx}>
                <Row className="mb-3  rounded p-2 align-items-center">
                  <Col md={5} xs={12} className="py-2">
                    <p style={{ color: "#fff" }}>Nom</p>
                    <Form.Control
                      type="text"
                      disabled
                      value={obParticuliere[val.position].nom}
                      onChange={({ target }) =>
                        onObservationsChange(val.position, target.value, "nom")
                      }
                    />
                  </Col>
                  <Col md={3} xs={12} className="py-2">
                    <p>Heures</p>
                    <Form.Control
                      type="time"
                      value={obParticuliere[val.position].heure}
                      onChange={({ target }) =>
                        onObservationsChange(
                          val.position,
                          target.value,
                          "heure"
                        )
                      }
                    />
                  </Col>
                  <Col md={1} xs={12} className="py-2">
                    <p>Clim14</p>
                    <Form.Control
                      type="text"
                      value={obParticuliere[val.position].clim14}
                      onChange={({ target }) =>
                        onObservationsChange(
                          val.position,
                          target.value,
                          "clim14"
                        )
                      }
                    />
                  </Col>
                  <Col md={1} xs={12} className="py-2">
                    <p>Clim15</p>
                    <Form.Control
                      type="text"
                      disabled={[
                        "Dernier relevé Hygrométrie",
                        "Premier relevé Hygrométrie",
                      ].includes(obParticuliere[val.position].nom)}
                      value={obParticuliere[val.position].clim15}
                      onChange={({ target }) =>
                        onObservationsChange(
                          val.position,
                          target.value,
                          "clim15"
                        )
                      }
                    />
                  </Col>
                  <Col md={1} xs={12} className="py-2">
                    <p>Clim16</p>
                    <Form.Control
                      type="text"
                      value={obParticuliere[val.position].clim16}
                      onChange={({ target }) =>
                        onObservationsChange(
                          val.position,
                          target.value,
                          "clim16"
                        )
                      }
                    />
                  </Col>
                  <Col md={1} xs={12} className="py-2">
                    <p>Clim25</p>
                    <Form.Control
                      type="text"
                      disabled={[
                        "Dernier relevé Hygrométrie",
                        "Premier relevé Hygrométrie",
                      ].includes(obParticuliere[val.position].nom)}
                      value={obParticuliere[val.position].clim25}
                      onChange={({ target }) =>
                        onObservationsChange(
                          val.position,
                          target.value,
                          "clim25"
                        )
                      }
                    />
                  </Col>
                </Row>
                {arr.length - 1 !== inx ? <hr /> : null}
              </div>
            ))}
          </Card.Body>
        </Card>

        {/* Etat des SSI */}
        <Card className="mt-5">
          <Card.Header className="bg-black text-white">
            Etat des SSI
          </Card.Header>
          <Card.Body>
            <Container>
              <Form.Group as={Col} controlId="exampleForm.ControlInput1">
                <Form.Label>IT Room</Form.Label>
                <Form.Select
                  aria-label="Horaire"
                  value={generalInfos.itRoom}
                  onChange={({ target }) =>
                    setGeneralInfos({
                      ...generalInfos,
                      itRoom: target.value,
                    })
                  }
                >
                  <option value="OK">OK</option>
                  <option value="Dérangement">Dérangement</option>
                  <option value="HS">HS</option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                as={Col}
                className="mt-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Data Center</Form.Label>
                <Form.Select
                  aria-label="Horaire"
                  value={generalInfos.dataCenter}
                  onChange={({ target }) =>
                    setGeneralInfos({
                      ...generalInfos,
                      dataCenter: target.value,
                    })
                  }
                >
                  <option value="OK">OK</option>
                  <option value="Dérangement">Dérangement</option>
                  <option value="HS">HS</option>
                </Form.Select>
              </Form.Group>
            </Container>
          </Card.Body>
        </Card>

        {/* Observations Générales */}
        <Card className="my-5">
          <Card.Header className="bg-black text-white">
            Observations Générales
          </Card.Header>
          <Card.Body>
            <>
              <div className="border rounded py-3">
                <Form.Label style={{ marginLeft: 15 }}>
                  Informations d’ordre générales & Synthèse
                </Form.Label>
                {Object.values(genInfos).map((val, inx) => (
                  <Row key={inx} className="mb-3 p-3 align-items-center">
                    <Col>
                      <Form.Group controlId="formGridEmail">
                        <Form.Control
                          placeholder="Entrez la description ici"
                          value={genInfos[val.position].title}
                          onChange={({ target }) =>
                            setGenInfos({
                              ...genInfos,
                              [val.position]: {
                                ...genInfos[val.position],
                                title: target.value,
                              },
                            })
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={2}>
                      <div className="d-flex justify-content-evenly p-3">
                        <Button
                          className="btn-sm btn-secondary"
                          onClick={() => addGenInfos(val.position + 1)}
                        >
                          Ajouter
                        </Button>

                        {inx >= 1 ? (
                          <Button
                            className="btn-sm btn-danger"
                            onClick={() => removeGenInfos(val.position)}
                          >
                            Retirer
                          </Button>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
              <Container className="border rounded p-3 mt-3">
                <Form.Label>Incident de sécurité</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={generalInfos.incident}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        incident: target.value,
                      })
                    }
                  />
                </Form.Group>
                {/* {Object.values(incident).map((val, inx) => (
                  <Row key={inx} className="mb-3 p-3 align-items-center">
                    <Col>
                      <Form.Group controlId="formGridEmail">
                        <Form.Control
                          placeholder="Entrez la description ici"
                          value={incident[val.position].title}
                          onChange={({ target }) =>
                            setIncidents({
                              ...incident,
                              [val.position]: {
                                ...incident[val.position],
                                title: target.value,
                              },
                            })
                          }
                        />
                      </Form.Group>
                    </Col>

                  </Row>
                ))} */}
              </Container>
            </>
          </Card.Body>
        </Card>

        <Row className="mb-5">
          <Col md={zipRef === "##2023A#" ? 3 : 6} xs={12}>
            <Button className="w-100 my-2" onClick={()=>onSave(false)}>
              Modifier
            </Button>
          </Col>
          {zipRef === "##2023A#" ? (
            <Col md={3} xs={12}>
              {/* <Button className="w-100 my-2" variant="success" onClick={sendPdf}>
              Télécharger
            </Button> */}
              {/* <PDFDownloadLink
              document={<MyDocument />}
              fileName={`${generalInfos.siteName}-rapport.pdf`}
            >
              <Button className="w-100 my-2" variant="success" onClick={sendPdf}>
                Télécharger
              </Button>
            </PDFDownloadLink> */}
              {/* <Pdf
              targetRef={ref}
              filename={`${generalInfos.siteName}-rapport.pdf`}
              options={options}
              x={0.5}
              y={0.5}
              onComplete={() => setPdfVisible(false)}
            >
              {({ toPdf }) => (
                <Button
                  className="w-100 my-2"
                  variant="success"
                  onClick={() => onGenerate(toPdf)}
                >
                  Télécharger
                </Button>
              )}
            </Pdf> */}
              <Button
                className="w-100 my-2"
                variant="success"
                onClick={() => pdfExportComponent.current.save()}
              >
                Télécharger
              </Button>
            </Col>
          ) : null}
          {zipRef === "##2023A#" ? (
            <Col md={3} xs={12}>
              <Button
                className="w-100 my-2"
                variant="danger"
                onClick={onDelete}
              >
                Supprimer
              </Button>
            </Col>
          ) : null}
          <Col md={zipRef === "##2023A#" ? 3 : 6} xs={12}>
            <Button className="w-100 my-2" variant="secondary" onClick={goBack}>
              Retour
            </Button>
          </Col>
        </Row>

        {/* <div className="justify-content-evenly d-flex mb-5">
        
           <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
            Télécharger
            <Button className="w-25" variant="success" onClick={onSave}>
            </Button>
          </PDFDownloadLink> 
       
        </div> */}
        {/* <div style={{ display: pdfVisible ? "block" : "none" }}></div> */}
        {zipRef === "##2023A#" ? (
          <>
            <h1 style={{ textAlign: "center" }}>Aperçu du PDF</h1>
            <MyDocument />
          </>
        ) : null}
        <div className="my-5" />
      </>
    </Container>
  );
}

export default AdminDetailForm;
