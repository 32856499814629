import moment from "moment";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import "./App.css";
import { getPadStart, SERVER } from "./utils";
import "./pagination.css";

function AdminHome() {
  // const data = JSON.parse(localStorage.getItem("storedVal"));
  const navigate = useNavigate();
  const itemsPerPage = 10;

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = reports.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(reports.length / itemsPerPage);

  const getRequests = async () => {
    setLoading(true);
    const result = await axios.get(SERVER + "reports");
    setReports(
      result.data
        .sort((a, b) => b.reportNum - a.reportNum)
        .filter((report) => !report.isDeleted)
    );
    setLoading(false);
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % reports.length;
    setItemOffset(newOffset);
  };

  const goToDetail = (itemID) => {
    navigate("/admin-detail-form/" + itemID);
  };

  const goToRendu = () => {
    navigate("/home-form");
  };

  const onSignout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    getRequests();
  }, []);

  return (
    <Container className="mt-5">
      <Form>
        <div className="d-flex justify-content-center w-100">
          <img src="logo_full.png" height={"150px"} alt="logo" />
        </div>
        <h1 className="text-center mb-5 fw-bold">Compte rendu de vacation</h1>

        <Row className="mb-5">
          <Col md={6} xs={6}>
            <Button className="w-75" onClick={goToRendu}>
              Nouveau
            </Button>
          </Col>

          <Col
            md={6}
            xs={6}
            className="d-flex"
            style={{ justifyContent: "flex-end" }}
          >
            <Button className="w-75" variant="secondary" onClick={onSignout}>
              Déconnexion
            </Button>
          </Col>
        </Row>

        {/* Informations Générales */}
        {loading ? (
          <div className="d-flex justify-content-center pt-5">
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: 10 }}
              />
              Chargement en cours...
            </Button>
          </div>
        ) : (
          <>
            {currentItems.map((data) => (
              <Card className="mt-4" key={data.id}>
                <Card.Header className="bg-black text-white"></Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={12} md={3}>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <small>No</small>
                        <h6 style={{ fontWeight: 530, marginTop: 3 }}>
                          {getPadStart(data?.reportNum) || 0}
                        </h6>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <small>Adresse</small>
                        <h6 style={{ fontWeight: 530, marginTop: 3 }}>
                          {JSON.parse(data.generalInfos)?.adresse}
                        </h6>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <small>Nom du site</small>
                        <h6 style={{ fontWeight: 530, marginTop: 3 }}>
                          {JSON.parse(data.generalInfos)?.siteName}
                        </h6>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <small>Date et heure de saisi</small>
                        <h6 style={{ fontWeight: 530, marginTop: 3 }}>
                          {new Date(data.createdAt).toLocaleString('fr-FR')}
                        </h6>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={12} md={3}>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <small>Date</small>
                        <h6 style={{ fontWeight: 530, marginTop: 3 }}>{`${
                          JSON.parse(data.generalInfos)?.vacationDu
                        } au ${JSON.parse(data.generalInfos)?.vacationAu}`}</h6>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <small>Horaire</small>
                        <h6 style={{ fontWeight: 530, marginTop: 3 }}>
                          {JSON.parse(data.generalInfos)?.horaire}
                        </h6>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <small>Agent</small>
                        <h6 style={{ fontWeight: 530, marginTop: 3 }}>
                          {JSON.parse(data.generalInfos)?.agent}
                        </h6>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Button
                          variant="outline-primary w-100"
                          onClick={() => goToDetail(data.id)}
                        >
                          Ouvrir
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
            <div className="my-5" />
            <ReactPaginate
              activeClassName={"item active "}
              breakClassName={"item break-me "}
              breakLabel={"..."}
              containerClassName={"pagination"}
              disabledClassName={"disabled-page"}
              marginPagesDisplayed={2}
              nextClassName={"item next "}
              nextLabel="→"
              onPageChange={handlePageClick}
              pageCount={pageCount}
              pageClassName={"item pagination-page "}
              pageRangeDisplayed={2}
              previousClassName={"item previous"}
              previousLabel="←"
            />
          </>
        )}
      </Form>
    </Container>
  );
}

export default AdminHome;
