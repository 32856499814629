import moment from "moment";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./App.css";
import { useNavigate } from "react-router-dom";
import { SERVER } from "./utils";

function HomeForm() {
  const navigate = useNavigate();

  const [generalInfos, setGeneralInfos] = useState({
    siteName: "SIPARTECH",
    adresse: "150 rue Gallieni, 92100 Boulogne-Billancourt",
    vacationDu: moment(new Date()).format("YYYY-MM-DD"),
    vacationAu: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
    horaire: "07h00 - 19h00",
    agent: "",
    itRoom: "Dérangement",
    dataCenter: "HS",
    incident: "",
  });

  const [parcoursRonde, setParcoursRonde] = useState([]);
  const [reports, setReports] = useState([]);
  const [selectedParcours, setSelectedParcours] = useState({});

  // const [parcoursRonde, setParcoursRonde] = useState({
  //   1: {
  //     position: 1,
  //     localisation: "Datacenter RDC Bat D, Local technique",
  //     pointsControl: "Température, Gaz, ondulateur, extincteurs",
  //   },
  //   2: {
  //     position: 2,
  //     localisation: "Datacenter RDC Bat D, Salle machine coté clim15",
  //     pointsControl: "Température, Hygrométrie, ondulateur, extincteurs",
  //   },
  //   3: {
  //     position: 3,
  //     localisation: "1er Etage Bat D, Direction, coté coin cuisine",
  //     pointsControl:
  //       "Fenêtres, extinction éclairage, chauffage, fuite, extincteurs",
  //   },
  //   4: {
  //     position: 4,
  //     localisation: "1er Etage Bat D, Bureau, coté réfectoire",
  //     pointsControl:
  //       "Fenêtres, extinction éclairage, chauffage, réfectoire, fuite, boite pharmacie, DAE",
  //   },
  //   5: {
  //     position: 5,
  //     localisation: "1er Etage Bat G, Support Client, coté salle de sport",
  //     pointsControl:
  //       "Fenêtres, extinction éclairage, chauffage, fuite, Travailleur isolé",
  //   },
  //   6: {
  //     position: 6,
  //     localisation: "Stock RDC Bat F",
  //     pointsControl: "Fenêtres",
  //   },
  //   7: {
  //     position: 7,
  //     localisation: "Stock/Datacenter RDC Bat E",
  //     pointsControl: "Fenêtres, fuite",
  //   },
  //   8: {
  //     position: 8,
  //     localisation: "1er Etage Bat E, Réfectoire et bureaux",
  //     pointsControl:
  //       "Fenêtres, extinction éclairage, chauffage, réfectoire, fuite, boite pharmacie",
  //   },
  //   9: {
  //     position: 9,
  //     localisation: "1er Etage Bat E salle SSI (IT Room)",
  //     pointsControl: "SSI",
  //   },
  //   10: {
  //     position: 10,
  //     localisation: "1er Etage Bat F (Salle Mercenaries)",
  //     pointsControl:
  //       "Fenêtres, extinction éclairage, chauffage, fuite, boite pharmacie",
  //   },
  //   11: {
  //     position: 11,
  //     localisation: "RDC Bat E Ancien bureau Informatique",
  //     pointsControl: "Fenêtres, extinction éclairage, fuite",
  //   },
  // });

  const [obParticuliere, setObParticuliere] = useState({
    1: {
      nom: "Premier relevé T°",
      heure: "",
      clim14: "",
      clim15: "",
      clim16: "",
      clim25: "",
      position: 1,
    },
    2: {
      nom: "Dernier relevé T°",
      heure: "",
      clim24: "",
      clim15: "",
      clim16: "",
      clim25: "",
      position: 2,
    },
    3: {
      nom: "Premier relevé Hygrométrie",
      heure: "",
      clim24: "",
      clim15: "",
      clim16: "",
      clim25: "",
      position: 3,
    },
    4: {
      nom: "Dernier relevé Hygrométrie",
      heure: "",
      clim24: "",
      clim15: "",
      clim16: "",
      clim25: "",
      position: 4,
    },
  });

  const [genInfos, setGenInfos] = useState({
    1: {
      title: "",
      position: 1,
    },
  });

  const [incident, setIncidents] = useState({
    1: {
      title: "",
      position: 1,
    },
  });

  const onChangeRonde = (id, value) => {
    setSelectedParcours({ ...selectedParcours, [id]: { id, value } });
  };

  const addGenInfos = (position) => {
    setGenInfos({
      ...genInfos,
      [position]: {
        title: "",
        position,
      },
    });
  };

  const addIncident = (position) => {
    setIncidents({
      ...incident,
      [position]: {
        title: "",
        position,
      },
    });
  };

  const addParcoursRonde = (position) => {
    setParcoursRonde({
      ...parcoursRonde,
      [position]: {
        position,
        localisation: "",
        pointsControl: "",
      },
    });
  };

  const addObParticuliere = (position) => {
    setObParticuliere({
      ...obParticuliere,
      [position]: {
        position,
        nom: "",
        heures: new Date(),
        clim14: "",
        clim15: "",
        clim16: "",
        clim25: "",
      },
    });
  };

  const removeParcoursRonde = (position) => {
    if (Object.values(parcoursRonde).length > 1) {
      setParcoursRonde((current) => {
        const copy = { ...current };
        delete copy[position];
        return copy;
      });
    }
  };

  const removeObParticuliere = (position) => {
    if (Object.values(obParticuliere).length > 1) {
      setObParticuliere((current) => {
        const copy = { ...current };
        delete copy[position];
        return copy;
      });
    }
  };

  const removeGenInfos = (position) => {
    if (Object.values(genInfos).length > 1) {
      setGenInfos((current) => {
        const copy = { ...current };
        delete copy[position];
        return copy;
      });
    }
  };

  const removeIncident = (position) => {
    if (Object.values(incident).length > 1) {
      setIncidents((current) => {
        const copy = { ...current };
        delete copy[position];
        return copy;
      });
    }
  };

  const onObservationsChange = (position, value, key) => {
    if (key === "heure") {
      if ([1, 3].includes(position)) {
        setObParticuliere({
          ...obParticuliere,
          1: {
            ...obParticuliere[1],
            [key]: value,
          },
          3: {
            ...obParticuliere[3],
            [key]: value,
          },
        });
      } else if ([2, 4].includes(position)) {
        setObParticuliere({
          ...obParticuliere,
          2: {
            ...obParticuliere[2],
            [key]: value,
          },
          4: {
            ...obParticuliere[4],
            [key]: value,
          },
        });
      }
      return;
    }

    setObParticuliere({
      ...obParticuliere,
      [position]: {
        ...obParticuliere[position],
        [key]: value,
      },
    });
  };

  const resetFields = () => {
    setGeneralInfos({
      siteName: "SIPARTECH",
      adresse: "150 rue Gallieni, 92100 Boulogne-Billancourt",
      vacationDu: moment(new Date()).format("YYYY-MM-DD"),
      vacationAu: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
      horaire: "07h00 - 19h00",
      agent: "",
      itRoom: "Dérangement",
      dataCenter: "HS",
      incident: "",
    });

    setObParticuliere({
      1: {
        nom: "Premier relevé T°",
        heure: new Date(),
        clim14: "",
        clim15: "",
        clim16: "",
        clim25: "",
        position: 1,
      },
      2: {
        nom: "Dernier relevé T°",
        heure: new Date(),
        clim24: "",
        clim15: "",
        clim16: "",
        clim25: "",
        position: 2,
      },
      3: {
        nom: "Premier relevé Hygrométrie",
        heure: new Date(),
        clim24: "",
        clim15: "",
        clim16: "",
        clim25: "",
        position: 3,
      },
      4: {
        nom: "Dernier relevé Hygrométrie",
        heure: new Date(),
        clim24: "",
        clim15: "",
        clim16: "",
        clim25: "",
        position: 4,
      },
    });

    setGenInfos({
      1: {
        title: "",
        position: 1,
      },
    });

    setIncidents({
      1: {
        title: "",
        position: 1,
      },
    });
  };

  const onSave = async () => {
    try {
      const toSave = {
        generalInfos,
        obParticuliere,
        genInfos,
        incident,
        selectedParcours,
      };

      // if (
      //   reports.filter(
      //     (report) =>
      //       JSON.parse(report.generalInfos)?.agent?.toLowerCase() === generalInfos.agent?.toLowerCase() &&
      //       JSON.parse(report.generalInfos)?.horaire === generalInfos.horaire &&
      //       JSON.parse(report.generalInfos)?.vacationDu ===
      //         generalInfos.vacationDu &&
      //       JSON.parse(report.generalInfos)?.vacationAu ===
      //         generalInfos.vacationAu
      //   ).length
      // ) {
      //   alert("Ce rapport existe déjà!");
      //   return;
      // }

      const result = await axios.post(SERVER + "reports", toSave);
      // localStorage.setItem('storedVal',toSave)
      if (result.status === 201) {
        alert("Enregistrement reussi");
        resetFields();
        navigate("/admin-home");
      } else if (result.status === 203) {
        alert("Ce rapport existe déjà!");
      } else {
        alert("Une erreur est survenue");
      }
    } catch (error) {
      console.log(error);
      alert("Une erreur est survenue");
    }
  };

  const saveParcours = () => {
    Object.values(parcoursRonde).forEach(async (val) => {
      const result = await axios.post(SERVER + "parcours", val);
      console.log(result.status);
    });
  };

  const getParcours = async () => {
    const result = await axios.get(SERVER + "parcours");
    setParcoursRonde(result.data);
  };

  const getRequests = async () => {
    const result = await axios.get(SERVER + "reports");
    setReports(result.data.filter((report) => !report.isDeleted));
  };

  const goBack = () => {
    navigate("/admin-home");
  };

  useEffect(() => {
    getParcours();
    // getRequests();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="mt-5">
      <Form>
        <div className="d-flex justify-content-center w-100">
          <img src="logo_full.png" height={"150px"} alt="logo" />
        </div>
        <h1 className="text-center mb-5 fw-bold">Compte rendu de vacation</h1>
        {/* <Button onClick={saveParcours}>Clik</Button> */}
        {/* Informations Générales */}
        <Card>
          <Card.Header className="bg-black text-white">
            Informations Générales
          </Card.Header>
          <Card.Body>
            <Row className="mb-3">
              <Col xs={12} md={6} className="py-2">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Nom du site</Form.Label>
                  <Form.Control
                    type="text"
                    value={generalInfos.siteName}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        siteName: target.value,
                      })
                    }
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="py-2">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Adresse</Form.Label>
                  <Form.Control
                    type="text"
                    value={generalInfos.adresse}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        adresse: target.value,
                      })
                    }
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={6} className="py-2">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Vacation du</Form.Label>
                  <Form.Control
                    type="date"
                    value={generalInfos.vacationDu}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        vacationDu: target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="py-2">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Au</Form.Label>
                  <Form.Control
                    type="date"
                    value={generalInfos.vacationAu}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        vacationAu: target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={6} className="py-2">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Horaire</Form.Label>

                  <Form.Select
                    aria-label="Horaire"
                    value={generalInfos.horaire}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        horaire: target.value,
                      })
                    }
                  >
                    <option value="07h00 - 19h00">07h00 - 19h00</option>
                    <option value="18h - 07h">18h - 07h</option>
                    <option value="19h - 07h">19h - 07h</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="py-2">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Agent</Form.Label>
                  <Form.Control
                    type="text"
                    value={generalInfos.agent}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        agent: target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* Parcours de ronde */}
        <Card className="mt-5">
          <Card.Header className="bg-black text-white mb-2">
            Parcours de ronde
          </Card.Header>

          <Table responsive="sm" striped={true}>
            <thead>
              <tr>
                <th>Position</th>
                <th>Localisation pointeaux</th>
                <th>Points de contrôle</th>
                <th>Etat</th>
              </tr>
            </thead>
            <tbody>
              {parcoursRonde.map((val, inx) => (
                <tr key={inx}>
                  <td>{inx + 1}</td>
                  <td>{val.localisation}</td>
                  <td>{val.pointsControl}</td>
                  <td>
                    <div className="d-flex">
                      <Form.Check
                        inline
                        label="RAS"
                        name="group1"
                        type={"checkbox"}
                        id={`inline-checkbox-1`}
                        style={{ color: "#3ba44d" }}
                        onChange={() => onChangeRonde(val.id, false)}
                        checked={!selectedParcours[val.id]?.value}
                      />
                      <Form.Check
                        inline
                        label="Anomalie"
                        name="group1"
                        type={"checkbox"}
                        id={`inline-checkbox-2`}
                        style={{ color: "#ffc038" }}
                        onChange={() => onChangeRonde(val.id, true)}
                        checked={selectedParcours[val.id]?.value}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>

        {/* Observations particulières Datacenter */}
        <Card className="mt-5">
          <Card.Header className="bg-black text-white">
            Observations particulières Datacenter
          </Card.Header>
          <Card.Body>
            {Object.values(obParticuliere).map((val, inx, arr) => (
              <div>
                <Row key={inx} className="mb-3  rounded p-2 align-items-center">
                  <Col md={5} xs={12} className="py-2">
                    <p style={{ color: "#fff" }}>Nom</p>
                    <Form.Control
                      type="text"
                      disabled
                      value={obParticuliere[val.position].nom}
                      onChange={({ target }) =>
                        onObservationsChange(val.position, target.value, "nom")
                      }
                    />
                  </Col>
                  <Col md={3} xs={12} className="py-2">
                    <p>Heures</p>
                    <Form.Control
                      type="time"
                      value={obParticuliere[val.position].heure}
                      onChange={({ target }) =>
                        onObservationsChange(
                          val.position,
                          target.value,
                          "heure"
                        )
                      }
                    />
                  </Col>
                  <Col md={1} xs={12} className="py-2">
                    <p>Clim14</p>
                    <Form.Control
                      type="text"
                      value={obParticuliere[val.position].clim14}
                      onChange={({ target }) =>
                        onObservationsChange(
                          val.position,
                          target.value,
                          "clim14"
                        )
                      }
                    />
                  </Col>
                  <Col md={1} xs={12} className="py-2">
                    <p>Clim15</p>
                    <Form.Control
                      type="text"
                      value={obParticuliere[val.position].clim15}
                      disabled={[
                        "Dernier relevé Hygrométrie",
                        "Premier relevé Hygrométrie",
                      ].includes(obParticuliere[val.position].nom)}
                      onChange={({ target }) =>
                        onObservationsChange(
                          val.position,
                          target.value,
                          "clim15"
                        )
                      }
                    />
                  </Col>
                  <Col md={1} xs={12} className="py-2">
                    <p>Clim16</p>
                    <Form.Control
                      type="text"
                      value={obParticuliere[val.position].clim16}
                      onChange={({ target }) =>
                        onObservationsChange(
                          val.position,
                          target.value,
                          "clim16"
                        )
                      }
                    />
                  </Col>
                  <Col md={1} xs={12} className="py-2">
                    <p>Clim25</p>
                    <Form.Control
                      type="text"
                      value={obParticuliere[val.position].clim25}
                      disabled={[
                        "Dernier relevé Hygrométrie",
                        "Premier relevé Hygrométrie",
                      ].includes(obParticuliere[val.position].nom)}
                      onChange={({ target }) =>
                        onObservationsChange(
                          val.position,
                          target.value,
                          "clim25"
                        )
                      }
                    />
                  </Col>
                </Row>
                {arr.length - 1 !== inx ? <hr /> : null}
              </div>
            ))}
          </Card.Body>
        </Card>

        {/* Etat des SSI */}
        <Card className="mt-5">
          <Card.Header className="bg-black text-white">
            Etat des SSI
          </Card.Header>
          <Card.Body>
            <Container>
              <Form.Group as={Col} controlId="exampleForm.ControlInput1">
                <Form.Label>IT Room</Form.Label>
                <Form.Select
                  aria-label="Horaire"
                  value={generalInfos.itRoom}
                  onChange={({ target }) =>
                    setGeneralInfos({
                      ...generalInfos,
                      itRoom: target.value,
                    })
                  }
                >
                  <option value="OK">OK</option>
                  <option value="Dérangement">Dérangement</option>
                  <option value="HS">HS</option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                as={Col}
                className="mt-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Data Center</Form.Label>
                <Form.Select
                  aria-label="Horaire"
                  value={generalInfos.dataCenter}
                  onChange={({ target }) =>
                    setGeneralInfos({
                      ...generalInfos,
                      dataCenter: target.value,
                    })
                  }
                >
                  <option value="OK">OK</option>
                  <option value="Dérangement">Dérangement</option>
                  <option value="HS">HS</option>
                </Form.Select>
              </Form.Group>
            </Container>
          </Card.Body>
        </Card>

        {/* Observations Générales */}
        <Card className="my-5">
          <Card.Header className="bg-black text-white">
            Observations Générales
          </Card.Header>
          <Card.Body>
            <div>
              <div className="border rounded p-3">
                <Form.Label>
                  Informations d’ordre générales & Synthèse
                </Form.Label>
                {Object.values(genInfos).map((val, inx) => (
                  <Row className="mb-3 py-2 align-items-center">
                    <Col xs={12} md={10}>
                      <Form.Group controlId="formGridEmail">
                        <Form.Control
                          placeholder="Entrez la description ici"
                          value={genInfos[val.position].title}
                          onChange={({ target }) =>
                            setGenInfos({
                              ...genInfos,
                              [val.position]: {
                                ...genInfos[val.position],
                                title: target.value,
                              },
                            })
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={2}>
                      <div className="d-flex justify-content-evenly p-3">
                        <Button
                          className="btn-sm btn-secondary"
                          onClick={() => addGenInfos(val.position + 1)}
                        >
                          Ajouter
                        </Button>
                        {inx >= 1 ? (
                          <Button
                            className="btn-sm btn-danger"
                            onClick={() => removeGenInfos(val.position)}
                          >
                            Retirer
                          </Button>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
              <div className="border rounded p-2 mt-3">
                <Form.Label>Incident de sécurité</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={generalInfos.incident}
                    onChange={({ target }) =>
                      setGeneralInfos({
                        ...generalInfos,
                        incident: target.value,
                      })
                    }
                  />
                </Form.Group>
                {/* {Object.values(incident).map((val, inx) => (
                  <Row key={inx} className="mb-3 py-2 align-items-center">
                    <Col xs={12} md={10}>
                      <Form.Group controlId="formGridEmail">
                        <Form.Control
                          placeholder="Entrez la description ici"
                          value={incident[val.position].title}
                          onChange={({ target }) =>
                            setIncidents({
                              ...incident,
                              [val.position]: {
                                ...incident[val.position],
                                title: target.value,
                              },
                            })
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={2}>
                      <div className="d-flex justify-content-evenly p-3">
                        <Button
                          className="btn-sm btn-secondary"
                          onClick={() => addIncident(val.position + 1)}
                        >
                          Ajouter
                        </Button>
                        {inx >= 1 ? (
                          <Button
                            className="btn-sm btn-danger"
                            onClick={() => removeIncident(val.position)}
                          >
                            Retirer
                          </Button>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                ))} */}
              </div>
            </div>
          </Card.Body>
        </Card>

        <Row className="mb-5">
          <Col md={6} xs={12}>
            <Button className="w-100 my-2" onClick={onSave}>
              Envoyer
            </Button>
          </Col>

          <Col md={6} xs={12}>
            <Button className="w-100 my-2" variant="secondary" onClick={goBack}>
              Retour
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default HomeForm;
