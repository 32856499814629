export const SERVER = "http://51.77.140.203:41008/api/v1/";
// export const SERVER = "https://zipsecurity-server.cogitfactory.com/api/v1/";
// export const SERVER = "https://zipdoc.cybexai-server.com/api/v1/";
// export const SERVER = "http://51.91.9.120:46586/api/v1/";

export const getPadStart = (num = "") => {
  if (num.length === 1) {
    return String(num).padStart(4, "0");
  } else if (num.length === 2) {
    return String(num).padStart(3, "0");
  } else if (num.length === 3) {
    return String(num).padStart(2, "0");
  } else if (num.length === 2) {
    return String(num).padStart(1, "0");
  } else {
    return num;
  }
};
