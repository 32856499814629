import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import AdminDetailForm from "./AdminDetailForm";
import AdminHome from "./AdminHome";
import "./App.css";
import HomeForm from "./HomeForm";
import PageNotFound from "./PageNotFound";
import Signin from "./Signin";

function App() {
  const zipRef = localStorage.getItem("zipRef");
  return (
    <div>
      <Routes>
        {!zipRef ? (
          <Route path={"/"} element={<Signin />} />
        ) : (
          <>
            <Route path={"/"} element={<AdminHome />} />
            <Route path={"/home-form"} element={<HomeForm />} />
            <Route path={"/admin-home"} element={<AdminHome />} />
            <Route
              path={"/admin-detail-form/:id"}
              element={<AdminDetailForm />}
            />
          </>
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
