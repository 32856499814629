import { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import "./App.css";
import axios from "axios"
import { SERVER } from "./utils";

function Signin() {

const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSave = () => {
    if (email === "admin@gmail.com" && password === "admin@password") {
      localStorage.setItem("zipRef","##2023A#")
      window.location.reload()
      // navigate('/admin-home')
    } else if(email === "agent@gmail.com" && password === "agent#2023"){
      localStorage.setItem("zipRef","##2023P##")
      window.location.reload()
      // navigate('/admin-home')
    } else{
      alert('Email ou mot de password incorrect')
    }
  };

  const [parcoursRonde, setParcoursRonde] = useState({
    1: {
      position: 1,
      localisation: "Datacenter RDC Bat D, Local technique",
      pointsControl: "Température, Gaz, ondulateur, extincteurs",
    },
    2: {
      position: 2,
      localisation: "Datacenter RDC Bat D, Salle machine coté clim15",
      pointsControl: "Température, Hygrométrie, ondulateur, extincteurs",
    },
    3: {
      position: 3,
      localisation: "1er Etage Bat D, Direction, coté coin cuisine",
      pointsControl:
        "Fenêtres, extinction éclairage, chauffage, fuite, extincteurs",
    },
    4: {
      position: 4,
      localisation: "1er Etage Bat D, Bureau, coté réfectoire",
      pointsControl:
        "Fenêtres, extinction éclairage, chauffage, réfectoire, fuite, boite pharmacie, DAE",
    },
    5: {
      position: 5,
      localisation: "1er Etage Bat G, Support Client, coté salle de sport",
      pointsControl:
        "Fenêtres, extinction éclairage, chauffage, fuite, Travailleur isolé",
    },
    6: {
      position: 6,
      localisation: "Stock RDC Bat F",
      pointsControl: "Fenêtres",
    },
    7: {
      position: 7,
      localisation: "Stock/Datacenter RDC Bat E",
      pointsControl: "Fenêtres, fuite",
    },
    8: {
      position: 8,
      localisation: "1er Etage Bat E, Réfectoire et bureaux",
      pointsControl:
        "Fenêtres, extinction éclairage, chauffage, réfectoire, fuite, boite pharmacie",
    },
    9: {
      position: 9,
      localisation: "1er Etage Bat E salle SSI (IT Room)",
      pointsControl: "SSI",
    },
    10: {
      position: 10,
      localisation: "1er Etage Bat F (Salle Mercenaries)",
      pointsControl:
        "Fenêtres, extinction éclairage, chauffage, fuite, boite pharmacie",
    },
    11: {
      position: 11,
      localisation: "RDC Bat E Ancien bureau Informatique",
      pointsControl: "Fenêtres, extinction éclairage, fuite",
    },
  });

  const saveParcours = async (e) => {
    e.preventDefault();
    Object.values(parcoursRonde).forEach(async (val) => {
      const result = await axios.post(SERVER + "parcours", val);
      console.log(result.status);
    });
  };

  const goToRendu = () => {
    navigate('/home-form')
  };

  return (
    <Container className="mt-5">
      <Form>
        <div className="d-flex justify-content-center w-100">
          <img src="logo_full.png" height={"150px"} alt="logo" />
        </div>
        <h1 className="text-center mb-5 fw-bold">Compte rendu de vacation</h1>

        <Row className="d-flex justify-content-center">
          <Col md={6} lg={6}>
          <Card>
            {/* <button onClick={saveParcours}>onSave</button> */}
            <Card.Header className="bg-black text-white">Connexion</Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="exampleForm.ControlInput1">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="exampleForm.ControlInput1">
                  <Form.Label>Mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                  />
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>
          </Col>
        </Row>

      <Row className="d-flex justify-content-center">
        <Col md={6} lg={6}>
        <div className="justify-content-center d-flex mt-5">
          <Button className="w-100" onClick={onSave}>
            Connexion
          </Button>
        </div>
        {/* <p className="text-center mt-3">Ou</p>
        <div className="justify-content-center d-flex mt-3">
          <Button className="w-100 btn-success" onClick={goToRendu}>
            Faire un compte rendu
          </Button>
        </div> */}
        </Col>
      </Row>
      </Form>
    </Container>
  );
}

export default Signin;
