import { Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./App.css";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  // const data = JSON.parse(localStorage.getItem("storedVal"));
  const navigate = useNavigate();

  const onBack = () => {
    navigate("/");
  };

  return (
    <Container className="mt-5">
      <Form>
        <div className="d-flex justify-content-center w-100">
          <img src="/logo_full.png" height={"150px"} alt="logo" />
        </div>
        <h1 className="text-center mb-5 fw-bold">Compte rendu de vacation</h1>
        <p className="text-center mb-5 fw-bold text-primary">Connectez-vous pour accéder à cette page </p>

        <div className="d-flex justify-content-center w-100">
        <Button variant="secondary" onClick={onBack}>
          Retour
        </Button>
        </div>
      </Form>
    </Container>
  );
}

export default PageNotFound;
